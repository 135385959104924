<template>
  <div id="thanks" class="flex">
    <p><strong>Thanks... check your email</strong></p>

    <router-link to="/">Woops... I used the wrong Github username</router-link>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
#thanks {
  flex-direction: column;
}
</style>
